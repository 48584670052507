<template>
    <div class="toasts">
        <transition-group name="fade">
            <div class="toast" v-for="toast in toasts" :class="toast.classes" @click="click(toast)" :key="toast.id">
                {{ toast.msg }}
                <span v-if="toast.html" v-html="toast.html"></span>
            </div>
        </transition-group>
    </div>
</template>

<script>
import events from './events';

export default {
    name: 'Toaster',
    data() {
        return {
            toasts: [],
        };
    },
    mounted() {
        events.$on('addToast', this.add);
    },
    methods: {
        add(toast) {
            this.toasts.push(toast);

            if (toast.close !== 'click') {
                this.startRemoveTimer(toast.id, toast.timeout || 4000);
            }
        },

        click(item) {
            this.remove(item.id);
        },

        remove(id) {
            if (!id) { return false; }
            const index = this.toasts.findIndex((it) => it.id === id);
            if (index > -1) {
                this.toasts.splice(index, 1);
            }
            return true;
        },

        startRemoveTimer(id, timeout) {
            setTimeout(() => {
                this.remove(id);
            }, timeout);
        },
    },
};
</script>

<style>
.toasts {
    position: fixed;
    bottom: 1.6rem;
    left: 1.6rem;
    max-width: 90vw;
    z-index: 150;
}

.toast {
    --toast-color: var(--color-white);
    --toast-bg: var(--color-black);
    padding: 1.2rem 2rem;
    color: var(--toast-color);
    display: block;
    float: left;
    clear: both;
    margin-top: 0.4rem;
    border-radius: var(--borderradius);
    cursor: pointer;
    transition: transform 0.2s ease;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.toast:hover {
    transform: scale(1.05);
}

.toast:not([class*='bg-']) {
    background-color: var(--toast-bg);
}

.toast[class*='bg-'] {
    --toast-color: var(--color-contrast-primary);
}

.toast .button,
.toast button {
    margin: -0.6rem -1.4rem -0.6rem 0.8rem;
}</style>
