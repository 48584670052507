import Vue from 'vue';
import VueRouter from 'vue-router';
import i18n from '@/i18n';

Vue.use(VueRouter);

const propvalidator_ids = (route) => {
    const props = { ...route.params };
    if (props.id) {
        props.id = +props.id;
    }
    return props;
};

const routes = [
    {
        path: '/home',
        alias: '/',
        name: 'home',
        component: () => import('@/views/Home.vue'),
        meta: {
            guest: true,
            meta: {},
        },
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('@/views/About.vue'),
        meta: {
            guest: true,
            meta: {
                title: i18n.t('menu.about'),
            },
        },
    },
    {
        path: '/products/:mode?/',
        name: 'products',
        component: () => import('@/views/Products.vue'),
        props: true,
        meta: {
            guest: true,
            meta: {
                title: i18n.t('menu.products'),
            },
        },
    },
    {
        path: '/contact',
        name: 'contact',
        component: () => import('@/views/Contact.vue'),
        meta: {
            guest: true,
            meta: {
                title: i18n.t('menu.contact'),
            },
        },
    },
    {
        path: '/news',
        name: 'news',
        component: () => import('@/views/News.vue'),
        meta: {
            guest: true,
            meta: {
                title: i18n.t('menu.news'),
            },
        },
    },
    {
        path: '/news/:id/:slug?',
        name: 'newsitem',
        component: () => import('@/views/Articles.vue'),
        props(route) {
            return propvalidator_ids(route);
        },
        meta: {
            guest: true,
            meta: {
                title: i18n.t('menu.news'),
            },
        },
    },
    {
        path: '/architects',
        name: 'architects',
        component: () => import('@/views/Articles.vue'),
        meta: {
            guest: true,
            meta: {
                title: i18n.t('menu.architects'),
            },
        },
    },
    {
        path: '/conditions',
        name: 'delivery_conditions',
        component: () => import('@/views/Articles.vue'),
        meta: {
            guest: true,
            meta: {
                title: i18n.t('menu.deliveryconditions'),
            },
        },
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: () => import('@/views/Articles.vue'),
        meta: {
            guest: true,
            meta: {
                title: i18n.t('menu.privacy'),
            },
        },
    },
    {
        path: '/404',
        name: '404',
        component: () => import('@/views/State.vue'),
        meta: {
            guest: true,
            meta: {
                title: 'Error 404',
            },
        },
    },
    {
        path: '/login',
        name: 'login',
        alias: '/logout',
        component: () => import('@/views/Login.vue'),
        meta: {
            guest: true,
            name: 'Login',
        },
    },
    {
        path: '/loggedout',
        name: 'loggedout',
        component: () => import('@/views/Loggedout.vue'),
        meta: {
            guest: true,
        },
    },
    {
        path: '/account',
        name: 'account',
        component: () => import('@/views/Account.vue'),
        meta: {
            guest: false,
            meta: {
                title: i18n.t('menu.account'),
            },
        },
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (typeof to.params.scrollTo !== 'undefined') {
            return to.params.scrollTo;
        }
        if (savedPosition) {
            return savedPosition;
        }
        if (to.hash) {
            return { selector: to.hash };
        }
        if (to.meta) {
            if (to.meta.scrollTop === false) { return false; }
            if (to.meta.scrollTop) {
                if (!Number.isNaN(to.meta.scrollTop)) {
                    return { x: 0, y: to.meta.scrollTop };
                }
                return { selector: to.meta.scrollTop };
            }
        }
        return { x: 0, y: 0 };
    },
});

router.beforeEach(async (to, from, next) => {
    if (store.state.app.nav_opened) {
        store.commit('toggleValue', { key: 'nav', action: 'close' });
    }
    if (!to.matched.length) {
        store.state.app.initialized.then(() => {
            if (router.resolve(to).resolved?.name) {
                next(to);
            } else {
                next({ path: '/404', params: { error: to.path } });
            }
        });
    } else if (!to.matched.every((page) => page.meta.guest)) { // Page requires auth
        if (store.getters['Auth/isAuthenticated']) {
            store.commit({ type: 'setMeta', from: 'router', data: to });
            next();
        } else {
            store.dispatch('Auth/startSession').then(() => {
                store.commit({ type: 'setMeta', from: 'router', data: to });
                next();
            }).catch(() => {
                next({ path: '/login', query: { redirect: to.path } });
            });
        }
    } else {
        store.commit({ type: 'setMeta', from: 'router', data: to });
        if (!to.path.startsWith('/logout')) {
            store.dispatch('Auth/startSession').catch(() => { });
        }
        next();
    }
});

router.onError((e) => {
    router.replace({ path: '/404', params: { error: e } }).catch(() => { });
});

export default router;
