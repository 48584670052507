import i18n from '@/i18n';
import http from '../services/http';

export default {
    namespaced: true,
    state: {
        authenticated: false,
        token: '',
        user: {},
    },
    getters: {
        isAuthenticated: (state) => state.authenticated,
        user: (state) => state.user,
        token: (state) => state.token,
        rights: (state) => (mod, permission) => {
            if (!state.authenticated || !state.user.rights_processed) {
                return false;
            }
            try {
                return (
                    state.user.rights_processed[mod]
          && state.user.rights_processed[mod].enabled
          && (!permission || state.user.rights_processed[mod].permissions[permission])
                );
            } catch (err) {
                return false;
            }
        },
    },
    mutations: {
        login(state, { token = '', user = {} }) {
            state.user = user;
            state.token = token;
            state.authenticated = !!(user && token);
            localStorage.setItem('authentication', JSON.stringify({ token }));
        },

        logout(state) {
            state.authenticated = false;
            state.user = {};
            state.token = '';
            localStorage.removeItem('authentication');
        },

        updateLang(state, lang) {
            i18n.locale = lang;
        },

        updateUser(state, user) {
            state.user = user;
        },

        setRights(state, rights_processed = {}) {
            state.user.rights_processed = rights_processed;
        },
    },
    actions: {
        login({ dispatch }, values) {
            return new Promise((resolve, reject) => {
                http
                    .post('login/verify/?table=clients', values)
                    .then((r) => dispatch('loginSuccess', { response: r, trigger: 'verify', values }))
                    .then((r) => { resolve(r); })
                    .catch((err) => {
                        console.log(err);
                        // eslint-disable-next-line
                        this._vm.$toast({ msg: i18n.t("login.toasts.error"), classes: "bg-danger" });
                        reject(err);
                    });
            });
        },

        loginSuccess({ commit, dispatch }, { response, values }) {
            return new Promise((resolve) => {
                if (response.status !== 'success') {
                    throw new Error('Error in login response', response);
                }
                if (response.users && response.users.length) {
                    commit('login', { user: response.users[0].user, token: response.users[0].sessions[0].token });
                    dispatch('updateUser', {
                        user: response.users[0],
                        application: {},
                    }).then(() => {
                        resolve(response);
                    });
                    dispatch('saveSmartLock', values);
                } else {
                    throw new Error('No token in response', response);
                }
            });
        },

        passreset(context, values) {
            return new Promise((resolve, reject) => {
                http
                    .post('login/forgotpassword/?table=clients', values)
                    .then((r) => {
                        if (!r || r.status !== 'success') {
                            throw new Error('Error in passreset response');
                        }
                        resolve();
                        // eslint-disable-next-line
                    this._vm.$toast(i18n.t("login.toasts.pass_sent"));
                    })
                    .catch((err) => {
                        try {
                            const data = JSON.parse(err.data);
                            if (data.code && data.code === 44) {
                                // eslint-disable-next-line
                        this._vm.$toast({
                                    msg: i18n.t('login.toasts.error_unsupported'),
                                    classes: 'bg-danger',
                                });
                            } else {
                                throw new Error('other error');
                            }
                        } catch (er) {
                            console.log(err);
                            // eslint-disable-next-line
                      this._vm.$toast({
                                msg: i18n.t('login.toasts.pass_unknown'),
                                classes: 'bg-danger',
                            });
                            reject(err);
                        }
                    });
            });
        },

        startSession({ dispatch }, { token } = {}) {
            return new Promise((resolve, reject) => {
                try {
                    let data = {};
                    if (token) {
                        data.token = token;
                    } else {
                        if (!localStorage.getItem('authentication')) {
                            throw new Error('no local authentication token');
                        }
                        data = JSON.parse(localStorage.getItem('authentication'));
                    }
                    if (data && data.token) {
                        dispatch('fetchSession', data)
                            .then((r) => {
                                resolve(r);
                            })
                            .catch((err) => {
                                dispatch('removeLoginSession');
                                reject(err);
                                // throw new Error('no valid session', err);
                            });
                    } else {
                        throw new Error('no local authentication token');
                    }
                } catch (error) {
                    dispatch('removeLoginSession');
                    reject(error);
                }
            });
        },

        removeLoginSession() {
            localStorage.removeItem('authentication');
        },

        fetchSession({ commit, dispatch }, { token }) {
            return new Promise((resolve, reject) => {
                http
                    .post('login/retrieve/?table=clients', { tokens: [token] })
                    .then((response) => {
                        if (
                            response.status === 'success'
                          && response.users
                          && response.users.length
                        ) {
                            commit('login', {
                                user: response.users[0].user,
                                token: response.users[0].sessions[0].token,
                            });

                            dispatch('updateUser', {
                                user: response.users[0],
                                application: {},
                            }).then(() => {
                                resolve(response);
                            });
                        } else {
                            reject(new Error('Invalid response'));
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        reject(new Error('Invalid response'));
                    });
            });
        },

        deleteSession(context, { token }) {
            http.delete('login/?table=clients', { token }).then((r) => {
                const url = r.data.logout_url;
                if (url) {
                    window.location.href = url;
                }
            });
        },

        updateUser({ commit, dispatch, rootState }, { user, application }) {
            return new Promise((resolve) => {
                if (user.lang && user.lang !== i18n.locale) {
                    commit('updateLang', user.lang);
                }
                commit('updateUser', user);
                if (typeof rootState.Users === 'object') {
                    dispatch('Users/processRights', undefined, { root: true });
                }
                if (window.bugsnagClient) {
                    commit('userBugsnag', user, { root: true });
                }

                dispatch('onLogin', application, { root: true }).then(() => {
                    resolve();
                });
            });
        },

        logout({ commit, dispatch, state }) {
            dispatch('onLogout', {}, { root: true });
            dispatch('deleteSession', { token: state.token });
            commit('logout');
            // if (router && router.currentRoute && !router.currentRoute.path.startsWith('/logout')) {
            //     router.push('/logout');
            // }

            // Google Smart Lock - Prevent automatic re-sign-in
            if (window.PasswordCredential || window.FederatedCredential) {
                navigator.credentials.preventSilentAccess();
            }
        },

        getSmartLock({ dispatch }, values) {
            if (window.PasswordCredential || window.FederatedCredential) {
                navigator.credentials.get({ password: true }).then((r) => {
                    if (r && r.id && r.password) {
                        values.email = r.id;
                        values.password = r.password;
                        dispatch('login', values);
                    }
                });
            }
        },

        saveSmartLock(context, values) {
            if (window.PasswordCredential || window.FederatedCredential) {
                // eslint-disable-next-line
        const cred = new PasswordCredential({ id: values.email, password: values.password });
                navigator.credentials.store(cred);
            }
        },
    },
};
