import Vue from 'vue';
import './registerServiceWorker';
import VueMeta from 'vue-meta';
import * as VueGoogleMaps from 'vue2-google-maps';
import Toaster from './plugins/toaster';
import App from './App.vue';
import i18n from './i18n';
import store from './store';
import router from './router';

Vue.config.productionTip = false;
Vue.config.ignoredElements = ['icon'];

global.store = store;
global.router = router;

// Filters
Vue.filter('cdnUrl', (val) => {
    if (val) {
        return `https://cdn.blixem.app/kaydenstone/${val}`;
    }
    return val;
});

// Plugins
Vue.use(VueMeta, {
    keyName: 'meta',
    refreshOnceOnNavigation: true,
});
Vue.use(VueGoogleMaps, {
    load: { key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY, libraries: 'places' },
});
Vue.use(Toaster);

new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount('#app');
