<template>
    <div id="app" :class="'page_' + $route.name">
        <Navigation class="bg-bg" logo="/img/logo.svg" :pages="$store.getters['pages']('header')" :languages="languages"
            :responsive="{ layout: 'default', breakpoint: 'l' }" width="xxl" />

        <div id="main" role="main">
            <router-view />
        </div>

        <Footer />

        <Viewer class="viewer__app" v-bind="{ images: overlayImages, options: overlayOptions }" @inited="$viewer = $event">
            <img v-for="src in overlayImages" :src="src" :key="src">
        </Viewer>

        <Toasts />
    </div>
</template>

<script>
import debounce from 'lodash.debounce';
import { component as Viewer } from 'v-viewer';

export default {
    name: 'App',
    components: {
        Footer: () => import('@/components/Footer.vue'),
        Navigation: () => import('@/components/ui/Navigation.vue'),
        Viewer,
    },

    created() {
        document.addEventListener('swUpdated', this.updateAvailable, { once: true });

        if (navigator.serviceWorker) {
            navigator.serviceWorker.addEventListener('controllerchange', () => {
                if (this.refreshing) return;
                this.refreshing = true;
                window.location.reload();
            });
        }
    },

    data() {
        return {
            overlayOptions: {
                navbar: false,
                title: false,
                toolbar: {
                    zoomIn: 0,
                    zoomOut: 0,
                    oneToOne: 0,
                    reset: 0,
                    prev: 1,
                    play: 0,
                    next: 1,
                    rotateLeft: 0,
                    rotateRight: 0,
                    flipHorizontal: 0,
                    flipVertical: 0,
                },
                tooltip: false,
                movable: true,
                rotatable: true,
                scalable: true,
                zoomable: true,
            },
            refreshing: false,
            registration: null,
        };
    },

    computed: {
        overlayImages() {
            return this.$store.state.app.overlayImages;
        },

        languages() {
            return {
                list: this.$store.getters['Language/valid'],
                current: this.$store.getters['Language/current'],
                layout: 'show',
            };
        },
    },

    mounted() {
        this.$store.dispatch('get', 'items/texts/1');
        this.$store.dispatch('Language/init');
        window.onresize = debounce(() => {
            this.$store.dispatch('resize');
        }, 500);
        this.$store.dispatch('resize');
    },

    methods: {
        onViewerImagesUpdate() {
            if (this.overlayImages.length) {
                this.$viewer.show();
            }
        },

        updateAvailable(event = {}) {
            this.registration = event.detail;
            setTimeout(this.refreshApp, 200);
        },

        refreshApp() {
            if (!this.registration || !this.registration.waiting) return;
            this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        },
    },

    watch: {
        overlayImages: 'onViewerImagesUpdate',
    },

    meta() {
        return this.$store.getters.meta;
    },
};
</script>

<style>
@import "assets/css/layout.css";
@import "assets/css/components.css";
@import "assets/css/text.css";
@import "assets/css/forms.css";
@import "assets/css/colors.css";
@import "assets/css/animations.css";
@import "assets/css/application.css";
@import "~@mdi/font/css/materialdesignicons.min.css";
@import "~viewerjs/dist/viewer.css";
</style>
