<template>
    <div>
        <Slider ratio="10by1" :image="$options.filters.cdnUrl(firstSlide.image)" />
        <div class="theme-light bg-white pagebg">
            <div v-if="articles.length">
                <Articles :articles="articles" class="section-margin-top-small" />

                <div class="c w-m" v-if="$route.name === 'newsitem'">
                    <router-link class="cursor-pointer hovercolor-hover size-4" :to="{ name: 'news' }">{{ $t('news.more') }}
                        ></router-link>
                    <NewsArticles :amount="3" :exclude="id" />
                </div>
            </div>
            <div v-else class="c w-xxl section-margin-top">
                <div class="flex -justify-center">
                    <CenteredText :title="$t('articles.empty_title')" :text="$t('articles.empty_text')" :link="{
                        title: $t('menu.home'),
                        to: { name: 'home' },
                        class: 'bg-white',
                        icon: 'chevron-right'
                    }" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ArticlesPage',
    components: {
        Articles: () => import('@/components/Articles.vue'),
        NewsArticles: () => import('@/components/NewsArticles.vue'),
        CenteredText: () => import('@/components/CenteredText.vue'),
        Slider: () => import('@/components/Slider.vue'),
    },
    props: {
        id: Number,
    },
    mounted() {
        this.getArticles();
    },
    data() {
        return {
            articles: [],
        };
    },
    computed: {
        firstSlide() {
            if (this.$store.state.data.slides && this.$store.state.data.slides.length > 0) {
                return this.$store.state.data.slides[0];
            }
            return {};
        },
    },
    methods: {
        getArticles() {
            this.articles = [];
            let apiID = this.id;
            let apiPageName = this.$route.name;
            if (this.$route.name === 'newsitem') {
                apiPageName = 'news';
            }
            if (this.$route.meta.meta.table) {
                apiPageName = this.$route.meta.meta.table;
                if (this.$route.meta.meta.id) {
                    apiID = this.$route.meta.meta.id;
                }
            }
            const endpoint = `items/${apiPageName}/${apiID ? `${apiID}/` : ''}`;
            this.$store.dispatch('get', endpoint).then((r) => {
                if (Array.isArray(r)) {
                    this.articles = r;
                } else {
                    this.articles = [r];
                }
            });
        },
    },
    watch: {
        '$route.name': 'getArticles',
    },
};
</script>
