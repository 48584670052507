import axios from 'axios';
// import { getAuthToken } from '@projectfive/v-auth';
// NOTE contains applogic for namespace and auth-tokens

// #region
// Handle repeated requests
const activeRequests = new Map();
const addRequest = ({ name = '' }) => {
    if (name) {
        if (activeRequests.has(name)) {
            activeRequests.get(name).cancel('canceled by next request');
        }
        const source = axios.CancelToken.source();
        activeRequests.set(name, source);
        return source.token;
    }
    return undefined;
};

const clearRequest = ({ name = '' }) => {
    if (name) {
        activeRequests.delete(name);
    }
};
// #endregion

// #region
// Instance with enabled repeated cancellation
const instance = axios.create({
    baseURL: `https://${process.env.VUE_APP_API_URL}/`,
    headers: {
        Authorization: `Bearer ${process.env.VUE_APP_API_TOKEN}`,
        namespace: `${process.env.VUE_APP_API_NAMESPACE}`,
    },
    timeout: process.env.NODE_ENV === 'development' ? 60000 : 15000,
});

instance.interceptors.request.use((config) => {
    // const authToken = getAuthToken();
    // if (authToken) {
    //     config.headers.Authorization = `Bearer ${authToken}`;
    // }

    config.cancelToken = addRequest(config);
    return config;
});

instance.interceptors.response.use(
    (response) => {
        clearRequest(response.config);
        if (typeof response.data !== 'object') {
            response.data = {};
        }
        response.data.statusCode = response.status;
        return response.data;
    },
    (error) => {
        if (error && error.config) {
            clearRequest(error.config);
        }
        return Promise.reject(error);
    },
);

export default instance;
