import i18n from '@/i18n';

export default {
    namespaced: true,
    state: {
        lang: process.env.VUE_APP_I18N_LOCALE,
    },

    getters: {
        currentObject: (state, getters) => getters.valid.find((lang) => lang.id === state.lang),

        current: (state, getters) => getters.currentObject.id,

        valid: (state, getters, rootState) => rootState.app.languages,

        others: (state, getters) => getters.valid.filter((lang) => lang.id !== getters.current),
    },

    mutations: {
        setLang(state, lang) {
            state.lang = lang;
            i18n.locale = lang;

            const elems = document.head.getElementsByClassName('meta__locale');
            if (elems.length) {
                elems[0].setAttribute('content', lang);
            }
        },
    },

    actions: {
        init({ dispatch }) {
            const storedLang = localStorage.getItem('language');
            const browserLang = navigator.language;
            if (storedLang) {
                dispatch('set', storedLang);
            } else if (browserLang) {
                dispatch('set', browserLang.substr(0, 2));
            }
        },

        set({ getters, commit }, lang) {
            if (!lang || lang === getters.current) { return false; }

            if (getters.valid.some((validLang) => validLang.id === lang)) { // New language is valid
                commit('setLang', lang);
                localStorage.setItem('language', lang);
            }
            return true;
        },
    },
};
