import Vue from 'vue';
import Vuex from 'vuex';
import router from '@/router';
import kebabcase from 'lodash.kebabcase';
import ArticleComponent from '@/views/Articles.vue';
import Language from './Language';
import Auth from './Auth';
import http from '../services/http';

Vue.use(Vuex);

let initResolver;
const initPromise = new Promise((resolve) => {
    initResolver = resolve;
});

export default new Vuex.Store({
    modules: {
        Auth,
        Language,
    },
    state: {
        app: {
            languages: [
                { id: 'nl', name: 'nl', title: 'Nederlands' },
                { id: 'en', name: 'en', title: 'English' },
            ],
            loading: false,
            loading_names: [],
            meta: {
                title: '',
                titleDefault: 'Kerna Luxury Stones',
                titleSeparator: ' - ',
                description: '',
                descriptionDefault: '',
                image: '',
                imageDefault: `https://${process.env.VUE_APP_URL}/img/icons/favicon.png`,
                keywords: '',
                keywordsDefault: '',
                url: '',
            },
            nav_opened: false,
            size: '',
            initialized: initPromise,
            navigation: [
                // carefull; the order is used in `setBrands`
                {
                    translation: 'menu.home',
                    to: { name: 'home' },
                    show: ['footer'],
                },
                {
                    translation: 'menu.about',
                    to: { name: 'about' },
                    show: ['header', 'footer'],
                },
                {
                    translation: 'menu.architects',
                    to: { name: 'architects' },
                    show: ['header', 'footer'],
                },
                {
                    translation: 'menu.brands',
                    show: ['header', 'footer'],
                    pages: [],
                },
                {
                    translation: 'menu.products',
                    to: { name: 'products' },
                    show: ['header', 'footer'],
                },
                {
                    translation: 'menu.stock',
                    to: { name: 'products', params: { mode: 'stock' } },
                    show: ['header', 'footer'],
                },
                {
                    translation: 'menu.contact',
                    to: { name: 'contact' },
                    show: ['header'],
                },
                {
                    translation: 'menu.account',
                    to: { name: 'account' },
                    show: ['header'],
                },
                {
                    translation: 'menu.deliveryconditions',
                    to: { name: 'delivery_conditions' },
                    show: ['footer'],
                },
                {
                    translation: 'menu.privacy',
                    to: { name: 'privacy' },
                    show: ['footer'],
                },
            ],
            overlayImages: [],
        },
        data: {},
    },
    getters: {
        meta: (state) => {
            const data = state.app.meta;

            return {
                title: data.title,
                titleTemplate: (title) => (title ? `${title} ${data.titleSeparator} ${data.titleDefault}` : data.titleDefault),
                meta: [
                    // Title
                    {
                        property: 'og:title',
                        content: data.title || data.titleDefault,
                    },
                    {
                        name: 'twitter:title',
                        content: data.title || data.titleDefault,
                    },
                    {
                        itemprop: 'name',
                        content: data.title || data.titleDefault,
                    },

                    // Description
                    {
                        name: 'description',
                        content: data.description || data.descriptionDefault,
                    },
                    {
                        name: 'keywords',
                        content: data.keywords || data.keywordsDefault,
                    },
                    {
                        property: 'og:description',
                        content: data.description || data.descriptionDefault,
                    },
                    {
                        name: 'twitter:description',
                        content: data.description || data.descriptionDefault,
                    },
                    {
                        itemprop: 'description',
                        content: data.description || data.descriptionDefault,
                    },

                    // Image
                    {
                        property: 'og:image',
                        content: data.image || data.imageDefault,
                    },
                    {
                        name: 'twitter:image:src',
                        content: data.image || data.imageDefault,
                    },
                    {
                        itemprop: 'image',
                        content: data.image || data.imageDefault,
                    },

                    // Sitename
                    {
                        property: 'og:site_name',
                        content: data.titleDefault,
                    },

                    // Url
                    {
                        property: 'og:url',
                        content: window.location.href,
                    },
                    {
                        name: 'twitter:site',
                        content: window.location.href,
                    },

                    // Others
                    {
                        name: 'og:locale',
                        content: state.Language.lang,
                    },
                    {
                        name: 'twitter:card',
                        content: 'summary',
                    },
                ],
                link: [
                    {
                        rel: 'canonical',
                        href: window.location.href,
                    },
                ],
            };
        },

        pages: (state) => (location) => state.app.navigation.filter((it) => it.show.includes(location)),

        size: (state) => (operator, breakpoint) => {
            const size = state.app.size;
            if (operator) {
                const breakpointNames = ['xs', 's', 'm', 'l', 'xl'];
                const sizeIndex = breakpointNames.indexOf(size);
                const breakpointIndex = breakpointNames.indexOf(breakpoint);
                if (operator === 'gte') {
                    return breakpointIndex <= sizeIndex;
                }
                if (operator === 'lte') {
                    return breakpointIndex >= sizeIndex;
                }
                if (operator === 'gt') {
                    return breakpointIndex > sizeIndex;
                }
                if (operator === 'lt') {
                    return breakpointIndex < sizeIndex;
                }
            }
            return size;
        },
    },
    mutations: {
        loadStart(state, name) {
            state.app.loading_names.push(name);
            state.app.loading = true;
        },

        loadEnd(state, name) {
            const index = state.app.loading_names.indexOf(name);
            if (index !== -1) {
                state.app.loading_names.splice(index, 1);
            }

            if (state.app.loading_names.length === 0) {
                state.app.loading = false;

                if (state.app.loading_fullscreen && !state.app.loading_fullscreen.message) {
                    state.app.loading_fullscreen.show = false;
                    state.app.loading_fullscreen.title = '';
                    state.app.loading_fullscreen.message = '';
                }
            }
        },

        setData(state, data) {
            state.data = data;
        },

        setBrands(state, brands) {
            state.app.navigation[3].pages = brands;
        },

        updateOverlay(state, images = []) {
            state.app.overlayImages = images;
        },

        setMeta(state, { from, data }) {
            if (from === 'router') {
                if (data.meta && data.meta.meta === false) {
                    return false;
                }
                if (!data.meta || typeof data.meta.meta === 'undefined') {
                    data = false;
                } else {
                    data = data.meta.meta;
                }
            }
            const statedata = state.app.meta;
            if (!data || typeof data !== 'object') {
                // reset
                data = {
                    title: '',
                    description: '',
                    image: '',
                    keywords: '',
                };
            }
            Object.assign(statedata, data);
            Object.keys(statedata).forEach((key) => {
                if (typeof statedata[key] === 'function') {
                    Vue.set(statedata, key, statedata[key]());
                }
            });
            state.app.meta = statedata;
            return true;
        },

        setAppData() { },

        toggleValue(state, data) {
            if (data.action === 'toggle') {
                state.app[`${data.key}_opened`] = !state.app[`${data.key}_opened`];
            } else if (data.action === 'close') {
                state.app[`${data.key}_opened`] = false;
            }
        },

        set_size(state, payload) {
            if (state.app) {
                state.app.size = payload;
            }
        },
    },
    actions: {
        get({ commit, dispatch }, page) {
            let params = {};
            if (typeof page === 'object') {
                params = page.params;
                page = page.page;
            }
            return new Promise((resolve, reject) => {
                http
                    .get(page, { params })
                    .then((r) => {
                        if (r && r.status === 'success') {
                            if (page === 'items/texts/1') {
                                commit('setData', r.results);
                                dispatch('initBrands', r.results.brands_highlights || []);
                                if (r.results.settings) {
                                    commit('setMeta', {
                                        from: 'config',
                                        data: {
                                            descriptionDefault: r.results.settings[0].metabeschrijving,
                                            keywordsDefault: r.results.settings[0].metatags,
                                        },
                                    });
                                }
                            }
                            resolve(r.results);
                        } else {
                            reject();
                        }
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        initBrands({ commit }, brands) {
            const brandPages = [];
            brands.forEach((brand) => {
                if (brand.path) {
                    const pathName = `brand-${kebabcase(brand.path)}`;
                    brandPages.push({
                        name_nl: brand.title_nl,
                        name_en: brand.title_en,
                        to: { name: pathName, params: { id: brand.id, slug: brand.path } },
                        show: ['header', 'footer'],
                        external_urls: brand.urls || [],
                    });

                    router.addRoute({
                        name: pathName,
                        path: brand.path,
                        component: ArticleComponent,
                        meta: {
                            guest: true,
                            meta: {
                                table: 'brands_highlights',
                                id: brand.id,
                                title: brand.title_nl,
                            },
                        },
                    });
                }
            });
            commit('setBrands', brandPages);
            initResolver();

            // Redirect to brandurl
            const loc = window.location;
            if (['', '/'].includes(loc.pathname)) {
                const brandMatch = brandPages.find((brand) => brand.external_urls.includes(loc.hostname));
                if (brandMatch) {
                    router.replace(brandMatch.to);
                }
            }
        },

        resize({ commit }) {
            const breakpoints = [544, 768, 992, 1200, 10000];
            const breakpointNames = ['xs', 's', 'm', 'l', 'xl'];
            const wWidth = window.innerWidth;
            const index = breakpoints.findIndex((it) => it > wWidth);

            commit('set_size', breakpointNames[index]);
        },

        onLogin({ commit }, applicationData = {}) {
            return new Promise((resolve) => {
                commit('setAppData', applicationData);
                resolve();
            });
        },

        onLogout({ commit }) {
            // reset application-contents
            commit('setAppData', { settings: { cdn: '' } });
        },
    },
});
