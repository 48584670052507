import Toasts from './Toasts.vue';
import events from './events';

const optionDefaults = {
    timeout: 4000,
};

const ToasterPlugin = {
    install(Vue, opts) {
        const options = { ...optionDefaults, ...opts };

        Vue.component('Toasts', Toasts);

        Vue.toast = (toast) => {
            if (typeof toast === 'string') {
                toast = { msg: toast };
            }
            const id = Math.floor((Math.random() * 1000) + 1);
            toast.id = id;

            toast = { ...options, ...toast };
            events.$emit('addToast', toast);
        };

        Vue.prototype.$toast = (toast) => {
            Vue.toast(toast);
        };
    },
};

export default ToasterPlugin;
